module.exports = {
  credentialActivated: 'Credential activated successfully',
  credentialDeactivated: 'Credential deactivated successfully',
  credentialCreated: 'Credential created successfully',
  credentialUpdated: 'Credential updated successfully',
  cronActivated: 'Cron activated successfully',
  cronDeactivated: 'Cron deactivated successfully',
  cronCreated: 'Cron created successfully',
  cronUpdated: 'Cron updated successfully',
  restActivated: 'Rest activated successfully',
  restDeactivated: 'Rest deactivated successfully',
  restCreated: 'Rest created successfully',
  restUpdated: 'Rest updated successfully',
  processActivated: 'Process activated successfully',
  processDeactivated: 'Process deactivated successfully',
  processCreated: 'Process created successfully',
  processUpdated: 'Process updated successfully',
  copiedClipboard: 'Copied to clipboard',
  userActivated: 'User activated successfully',
  userDeactivated: 'User deactivated successfully',
  userCreated: 'User created successfully',
  userUpdated: 'User updated successfully',
  roleCreated: 'Role created successfully',
  roleUpdated: 'Role updated successfully',
  test: 'Test Successful',
  templateCreated: 'Template created successfully',
  templateUpdated: 'Template updated successfully',
  translationCreated: 'Translation created successfully',
  translationUpdated: 'Translation updated successfully',
  translationDeleted: 'Translation deleted successfully',
  resendPassword: 'Password reset instructions sent successfully',
  settingActivated: 'Setting activated successfully',
  settingDeactivated: 'Setting deactivated successfully',
  settingCreated: 'Setting created successfully',
  settingUpdated: 'Setting updated successfully',
  stepCopy: 'Step copied successfully',
  cronExecute: 'Cron execute successful with Event ID:',
  restUrlCheckIsExist: 'Check successful, URL exists on trigger ID:',
  restUrlCheckNoExist: 'Check successful, URL doesn\'t exists',
  moduleCreated: 'Module created successfully',
  moduleUpdated: 'Module updated successfully',
  moduleExport: 'Module exported successfully',
  moduleImport: 'Module imported successfully',
  moduleValidate: 'Module validated successfully',
  validationCreated: 'Validation rule created successfully',
  validationUpdated: 'Validation rule updated successfully',
  apiKeyGeneration: 'ApiKey generated successfully',
  moduleUploaded: 'Module uploaded successfully',
  moduleDownloaded: 'Module downloaded successfully',
  moduleInstalled: 'Module installed successfully',
  pluginActivated: 'Plugin activated successfully',
  pluginDeactivated: 'Plugin deactivated successfully',
  pluginCreated: 'Plugin created successfully',
  pluginUpdated: 'Plugin updated successfully',
  pluginImport: 'Plugin imported successfully',
  reviewSubmit: 'Review submitted successfully',
  userSettingsSubmit: 'User settings submitted successfully',
  passwordReset: 'Password reset email sent successfully',
  passwordSet: 'Password set successfully',
  messagingActivated: 'Messaging activated successfully',
  messagingDeactivated: 'Messaging deactivated successfully',
  messagingUpdated: 'Messaging updated successfully',
  messagingCreated: 'Messaging created successfully',
  libraryActivated: 'Library activated successfully',
  libraryDeactivated: 'Library deactivated successfully',
  libraryUpdated: 'Library updated successfully',
  libraryCreated: 'Library created successfully',
  libraryUploaded: 'Library uploaded successfully',
  passwordChanged: 'Password changed successfully',
  storageUpdated: 'Storage updated successfully',
  storageCreated: 'Storage created successfully',
  eventHandlerActivated: 'Event Handler activated successfully',
  eventHandlerDeactivated: 'Event Handler deactivated successfully',
  eventHandlerUpdated: 'Event Handler updated successfully',
  eventHandlerCreated: 'Event Handler created successfully',
  entityActivated: 'Entity activated successfully',
  entityDeactivated: 'Entity deactivated successfully',
  entityCreated: 'Entity created successfully',
  entityUpdated: 'Entity updated successfully',
  entityGenerated: 'Entity generated successfully',
  twoFactorAuthEnabled: 'Two Factor Authentication enabled',
  twoFactorAuthDisabled: 'Two Factor Authentication disabled',
  interruptEventsSuccess: 'Interrupt events successful'
}
