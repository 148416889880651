<template>
  <v-layout class="fill-height bg-full">
    <!-- Navigation -->
    <v-navigation-drawer
      v-if="isLoggedIn && $route.name !== 'set-password'"
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
      floating
      clipped
      width="220"
      class="background-custom"
    >
      <!-- Navigation menu -->
      <div class="toggle-menu">
        <v-btn
          class="nav-toggle"
          icon
          :class="(!mini) ? 'btn-position' : 'mt-2'"
          @click.stop="mini = !mini"
        >
          <v-icon v-if="mini">mdi-chevron-right </v-icon>
          <v-icon v-else> mdi-chevron-left </v-icon>
        </v-btn>
      </div>
      <flowy-main-menu :menu="menuItems" :mini="mini" class="mt-1"/>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      flat
      class="app-bar-full background-custom elevation-0"
      clipped-left
    >
      <v-card
        class="flex-grow-1 d-flex pa-1 pl-0 background-custom elevation-0"
        tile
        flat
      >
        <div class="d-flex flex-grow-1 align-center">

          <div class="d-flex pl-1 align-center clickable-simple">
            <img
              v-if="$vuetify.theme.dark"
              src="/images/logo_light.png"
              height="48"
              alt="logo"
              class="mr-1"
              @click="$router.push({ name: 'home' })"
            >
            <img
              v-else
              src="/images/logo_dark.png"
              height="48"
              alt="logo"
              class="mx-3"
              @click="$router.push({ name: 'home' })"
            >
          </div>

          <v-spacer></v-spacer>

          <div class="last-edited-hide align-center mr-2" style="height: 100%">
            <header-search v-if="isLoggedIn && $route.name !== 'set-password'" :search-field="searchField"></header-search>
            <div v-if="isLoggedIn && $route.name !== 'set-password'" class="ml-1 mr-1"></div>
            <v-autocomplete
              v-if="savedLocations && savedLocations.length > 0 && isLoggedIn && $route.name !== 'set-password'"
              ref="autocomplete"
              v-model="lastEditValue"
              :placeholder="$lang.header.recentlyAccessed"
              :items="savedLocations"
              :class="{ opened: opened }"
              item-text="name"
              item-value="fullPath"
              outlined
              dense
              hide-details
              auto
              :append-icon="!opened ? '' : 'mdi-chevron-up'"
              :search-input.sync="lastEditValueSearch"
              prepend-inner-icon="mdi-history"
              clearable
              :menu-props="{closeOnContentClick: true, minWidth: 300, left: true}"
              class="recently-accessed-input"
              @focus="opened = true"
              @blur="opened = false"
            >

              <template v-slot:selection="data">
                <span><v-icon>{{ data.item.icon }}</v-icon></span><span class="ml-3">{{ data.item.name }}</span>
              </template>

              <template v-slot:item="data">
                <div class="d-flex" >
                  <v-list-item-avatar>
                    <v-icon>{{ data.item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </div>
              </template>
            </v-autocomplete>
          </div>

          <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
            <header-lang @changeLang="changeLang"/>
          </div>

          <div v-if="isLoggedIn && $route.name !== 'set-password'" :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
            <header-user @changeTheme="changeTheme"/>
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-container
      :fluid="!isContentBoxed"
      :style="$route.name === 'home' ? 'padding: 0 !important' : 'padding-top: 70px; padding-bottom: 0;'"
    >
      <router-view v-if="!loadingProfile" :mini="mini" :class="{'fill-height': true }"></router-view>
    </v-container>
  </v-layout>
</template>

<script>
import config from '@/configs'
import FlowyMainMenu from '@/components/menu/FlowyMainMenu'
import HeaderUser from '@/components/header/HeaderUser'
import HeaderLang from '@/components/header/HeaderLang'
import HeaderSearch from '@/components/header/HeaderSearch'
import auth from '@/auth/index'
import { mapActions, mapGetters } from 'vuex'
import colorsConfig from '@/configs/colorSchemes'
import { getAllUserSettingsUsingGET as getProfile, saveUserSettingsUsingPOST as saveProfile } from '@/utils/api'

export default {
  components: {
    FlowyMainMenu,
    HeaderUser,
    HeaderLang,
    HeaderSearch
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingProfile: false,
      opened: false,
      lastEditValue: '',
      lastEditValueSearch: '',
      frontEnd: '',
      frontEndBuildTime: '',
      searchField: '',
      backEnd: {
        branch: '',
        commit: {
          id: '',
          time: ''
        }
      },
      isLoggedIn: false,
      config,
      drawer: null,
      mini: false,
      isContentBoxed: false
    }
  },
  computed: {
    ...mapGetters('app', {
      savedLocations: 'savedLocations',
      userSettings: 'userSettings',
      userRoles: 'userRoles'
    }),
    menuItems() {
      return [{
        text: '',
        items: [
          {
            icon: 'mdi-view-dashboard-outline',
            key: 'menu.dashboard',
            text: this.$lang.routes.home,
            link: 'home',
            exact: true
          }
        ]
      }, {
        text: this.$lang.routes.pages,
        key: 'menu.others',
        items: [
          {
            icon: 'mdi-rocket-launch', key: 'menu.trigger', text: this.$lang.routes.triggers, regex: 'triggers',
            items: [
              { icon: 'mdi-briefcase-clock-outline', key: 'menu.cron', text: this.$lang.routes.cron, link: 'cron' },
              { icon: 'mdi-briefcase-outline', key: 'menu.event-handler', text: this.$lang.routes.eventsHandler, link: 'eventHandler' },
              { icon: 'mdi-message-text-outline', key: 'menu.messaging', text: this.$lang.routes.messaging, link: 'messaging' },
              { icon: 'mdi-web', key: 'menu.rest', text: this.$lang.routes.rest, link: 'rest' }
            ]
          },
          { icon: 'mdi-console-network', key: 'menu.processes', text: this.$lang.routes.processes, link: 'processes' },
          { icon: 'mdi-lock', key: 'menu.cred', text: this.$lang.routes.credentials, link: 'credentials' },
          {
            icon: 'mdi-text-shadow', key: 'menu.templates.overview', text: this.$lang.routes.templates, regex: 'templates',
            items: [
              {
                icon: 'mdi-text-box-outline',
                key: 'menu.templates',
                text: this.$lang.routes.templates,
                link: 'templates'
              },
              { icon: 'mdi-text', key: 'menu.translations', text: this.$lang.routes.translations, link: 'translations' }
            ]
          },
          { icon: 'mdi-magnify-scan', key: 'menu.validations', text: this.$lang.routes.validations, link: 'validations' },
          { icon: 'mdi-cube', key: 'menu.storage', text: this.$lang.routes.storage, link: 'storage' },
          { icon: 'mdi-puzzle-outline', key: 'menu.plugins', text: this.$lang.routes.plugins, link: 'plugins' },
          { icon: 'mdi-library-outline', key: 'menu.libraries', text: this.$lang.routes.libraries, link: 'libraries' },
          { icon: 'mdi-database-settings-outline', key: 'menu.entities', text: this.$lang.routes.entities, link: 'entities' },
          {
            icon: 'mdi-view-module-outline', key: 'menu.modules', text: this.$lang.routes.modules, regex: 'modules',
            items: [
              { icon: 'mdi-briefcase-edit-outline', key: 'menu.local', text: this.$lang.routes.local, link: 'local' },
              { icon: 'mdi-briefcase-download-outline', key: 'menu.hub', text: this.$lang.routes.hub, link: 'hub' }
            ]
          },
          {
            icon: 'mdi-account-box-outline', key: 'menu.permissions', text: this.$lang.routes.permissions, regex: 'permissions',
            hide: this.roleChecker(['USER_CREATOR', 'ROLE_CREATOR', 'SUPER_USER']),
            items: [
              {
                icon: 'mdi-account',
                key: 'menu.users',
                text: this.$lang.routes.users,
                link: 'users',
                hide: this.roleChecker(['USER_CREATOR', 'SUPER_USER'])
              },
              {
                icon: 'mdi-account-group-outline',
                key: 'menu.roles',
                text: this.$lang.routes.roles,
                link: 'roles',
                hide: this.roleChecker(['ROLE_CREATOR', 'SUPER_USER'])
              }
            ]
          },
          {
            icon: 'mdi-file-clock-outline', key: 'menu.logs', text: this.$lang.routes.logs, regex: 'logs',
            items: [
              {
                icon: 'mdi-debug-step-over',
                key: 'menu.logsProcesses',
                text: this.$lang.routes.logsProcesses,
                link: 'logs-processes'
              },
              {
                icon: 'mdi-debug-step-into',
                key: 'menu.logsSteps',
                text: this.$lang.routes.logsSteps,
                link: 'logs-steps'
              },
              { icon: 'mdi-note', key: 'menu.logsEvents', text: this.$lang.routes.logsEvents, link: 'logs-events' },
              {
                icon: 'mdi-factory',
                key: 'menu.logsInstances',
                text: this.$lang.routes.instances,
                link: 'logs-instances'
              },
              {
                icon: 'mdi-engine',
                key: 'menu.internalJobs',
                text: this.$lang.labels.internalJobs,
                link: 'logs-internal-jobs'
              },
              {
                icon: 'mdi-marker',
                key: 'menu.telemetry',
                text: this.$lang.routes.telemetries,
                link: 'logs-telemetry',
                hide: this.roleChecker(['TELEMETRY_ADMIN', 'SUPER_USER'])
              }
            ]
          },
          {
            icon: 'mdi-cog', key: 'menu.settings', text: this.$lang.routes.settings, regex: 'settings',
            items: [
              {
                icon: 'mdi-cog-outline',
                key: 'menu.settings.global',
                text: this.$lang.routes.global,
                link: 'globalSettings',
                hide: this.roleChecker(['GLOBAL_SETTING', 'SUPER_USER'])
              },
              {
                icon: 'mdi-flip-to-back',
                key: 'menu.settings.instance.processing',
                text: this.$lang.routes.instanceProcessing,
                link: 'instance-processing'
              },
              {
                icon: 'mdi-flip-to-back',
                key: 'menu.settings.instance.admin',
                text: this.$lang.routes.instanceAdmin,
                link: 'instance-settings'
              },
              {
                icon: 'mdi-flip-vertical',
                key: 'menu.settings.process',
                text: this.$lang.routes.process,
                link: 'process'
              },
              {
                icon: 'mdi-puzzle-star-outline',
                key: 'menu.settings.plugin',
                text: this.$lang.routes.plugins,
                link: 'plugin'
              }
            ]
          },
          {
            icon: 'mdi-tools', key: 'menu.admin', text: this.$lang.routes.admin, regex: 'admin',
            items: [
              { icon: 'mdi-radar',
                key: 'menu.mass.changes',
                text: this.$lang.routes.massChanges,
                link: 'mass-changes'
              },
              { icon: 'mdi-history',
                key: 'menu.history',
                text: this.$lang.routes.history,
                link: 'history'
              },
              { icon: 'mdi-safe', key: 'menu.gdpr', text: this.$lang.routes.gdpr, link: 'gdpr', hide: this.roleChecker(['GDPR_ADMIN', 'SUPER_USER']) },
              { icon: 'mdi-database-search-outline', key: 'menu.browseData', text: this.$lang.routes.browseData, link: 'browseData' }
            ]
          },
          {
            icon: 'mdi-bookmark', key: 'menu.links', text: this.$lang.routes.links,
            items: [
              {
                icon: 'mdi-code-json',
                key: 'menu.logsServer',
                text: this.$lang.labels.swagger,
                link: '',
                fullLink: true,
                specKey: 'swagger'
              },
              {
                icon: 'mdi-ticket',
                key: 'menu.serviceDesk',
                text: this.$lang.labels.serviceDesk,
                link: '',
                fullLink: true,
                specKey: 'serviceDesk'
              },
              {
                icon: 'mdi-note-text-outline',
                key: 'menu.logsServer',
                text: this.$lang.labels.logsServer,
                link: '',
                fullLink: true,
                specKey: 'logsServer'
              },
              {
                icon: 'mdi-format-list-checkbox',
                key: 'menu.metricServer',
                text: this.$lang.labels.metricServer,
                link: '',
                fullLink: true,
                specKey: 'metricServer'
              },
              {
                icon: 'mdi-chart-box-outline',
                key: 'menu.visualisationServer',
                text: this.$lang.labels.visualisationServer,
                link: '',
                fullLink: true,
                specKey: 'visualisationServer'
              }
            ]
          }
        ]
      }]
    }
  },
  watch: {
    lastEditValue: {
      handler() {
        this.goToLast()
      }
    }
  },
  mounted() {
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', this.colorThemeListener)

    this.frontEnd = localStorage.frontendVersion ? localStorage.frontendVersion : ''

    this.frontEndBuildTime = localStorage.frontendBuildtime ? localStorage.frontendBuildtime : ''

    this.backEnd = localStorage.backendVersion ? JSON.parse(localStorage.backendVersion) : {
      branch: '',
      commit: {
        id: '',
        time: ''
      }
    }

    const id = localStorage.userData ? JSON.parse(localStorage.userData).authToken : false

    auth.userData.loggedInStatus = !!id

    this.isLoggedIn = !!id
  },
  created() {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches

    if (auth.isLoggedIn()) {
      this.fetchUserProfile()
    } else {
      if (localStorage.selectedTheme && JSON.parse(localStorage.selectedTheme) !== isDark) {
        this.$vuetify.theme.dark = JSON.parse(localStorage.selectedTheme)
        this.globalTheme(JSON.parse(localStorage.selectedTheme))
      } else {
        this.$vuetify.theme.dark = isDark
        localStorage.setItem('selectedTheme', JSON.stringify(isDark))
        this.globalTheme(isDark)
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('change', this.colorThemeListener)
  },
  methods: {
    ...mapActions('app', [
      'globalTheme',
      'userSettingsSave'
    ]),
    roleChecker(rolesArray) {
      return !(this.userRoles && this.userRoles.some((role) => rolesArray.includes(role.name)))
    },
    fetchUserProfile() {
      this.loadingProfile = true
      getProfile()
        .then((res) => {
          const tempData = res.data.data

          if (!tempData.display) tempData.display = {
            showCreatedOn: true,
            showModifiedOn: true,
            showId: true,
            showComments: false,
            showCopyPaste: false
          }

          this.userSettingsSave(tempData)

          const localTheme = localStorage.selectedTheme ? JSON.parse(localStorage.selectedTheme) : null

          if (tempData.visuals?.selectedColorScheme) this.changeColors(tempData.visuals.selectedColorScheme)
          if (tempData.visuals?.selectedTheme) this.changeTheme(localTheme !== null ? localTheme : tempData.visuals.selectedTheme === 'dark')
          if (tempData.general?.selectedLanguage) this.changeLang(tempData.general.selectedLanguage)

          this.loadingProfile = false
        })
        .catch((err) => {
          console.log(err)
          this.loadingProfile = false
        })
    },
    saveUserProfile() {
      this.err = ''
      this.success = ''
      saveProfile({
        body: {
          settings: {
            general: {
              selectedLanguage: localStorage.selectedLanguage
            },
            visuals: {
              selectedTheme: localStorage.selectedTheme ? JSON.parse(localStorage.selectedTheme) ? 'dark' : 'light' : 'light',
              selectedColorScheme: localStorage.selectedColorScheme ? localStorage.selectedColorScheme : 'default'
            },
            display: this.userSettings.display
          }
        }
      })
    },
    goToLast() {
      if (this.lastEditValue && this.$route.path !== this.lastEditValue) this.$router.push({ path: this.lastEditValue })
      this.$nextTick(() => {
        this.lastEditValue = null
        this.$refs.autocomplete.internalSearch = null
      })
    },
    changeLang(lang) {
      const isDifferent = lang !== localStorage.selectedLanguage

      this.$lang.setLang(lang)
      localStorage.setItem('selectedLanguage', lang)
      if (isDifferent) this.saveUserProfile()
      if (this.$route.params.lang && this.$route.params.lang !== lang) this.$router.push({ name: this.$route.name, params: { lang } })
    },
    changeTheme(theme) {
      const isDifferent = String(theme) !== localStorage.selectedTheme

      this.$vuetify.theme.dark = theme
      localStorage.setItem('selectedTheme', theme)
      if (isDifferent) this.saveUserProfile()
      this.globalTheme(theme)
    },
    changeColors(type) {
      const { dark } = colorsConfig[type]
      const { light } = colorsConfig[type]

      if (dark && light) {
        localStorage.setItem('selectedColorScheme', type)

        // set themes
        Object.keys(dark).forEach((i) => {
          this.$vuetify.theme.themes.dark[i] = dark[i]
        })
        Object.keys(light).forEach((i) => {
          this.$vuetify.theme.themes.light[i] = light[i]
        })
      }
    },
    colorThemeListener(event) {
      if (event.matches) {
        this.$vuetify.theme.dark = true
        localStorage.setItem('selectedTheme', 'true')
      } else {
        this.$vuetify.theme.dark = false
        localStorage.setItem('selectedTheme', 'false')
      }
    }
  }
}
</script>

<style lang="scss">
.app-bar-full {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0;
    align-items: normal;
  }
}

.bg-full {
  background: var(--v-customBg-base) !important;
}

.last-edited-hide {
  display: flex !important;
}

@media screen and (max-width: 600px) {
  .last-edited-hide {
    display: none !important;
  }
}

.toggle-menu {
  .v-size--default {
    width: 28px !important;
    height: 28px !important;
  }
}

.v-navigation-drawer--mini-variant, .v-navigation-drawer {
  overflow: visible !important;
}

.nav-toggle {
  position: absolute;
  height: 3rem;
  z-index: 1;
  right: 14px;
  top: 100px;
  bottom: 0;
  background: #fff;

  .v-btn {
    margin: auto;
    border: thin solid white !important;
  }
}

.btn-position {
  right: 12px!important;
  top: 81px;
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #424242;
  background-color: #424242;
}

.recently-accessed-input {
  transition: width .3s ease-in-out !important;
  width: 48px ;
  margin-right: 0 !important;
  margin-left: auto;

  //.v-text-field--outlined fieldset {
  //  transition-duration: 2s!important;
  //}

    &.opened {
      transition: width .3s !important;
      width: 400px;
    }

}

</style>
