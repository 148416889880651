import Vue from 'vue'
import {
  getUserNameUsingGET as getUserName,
  getProcessesUsingGET as getProcesses,
  createProcessUsingPOST as createProcess,
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplateTranslationsUsingGET as getTranslations,
  getRolesUsingGET as getRoles
} from '@/utils/api'
import { format } from 'date-fns'
import router from '@/router'

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  return arr1.every((value, index) => value === arr2[index])
}

export const objectsEqual = (obj1, obj2) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
  }

  return Object.keys(obj1).every((key) => obj1[key] === obj2[key])
}

export const predictBestTimeUnitFromMsValue = async (value) => {
  if (Number(value / 1000 / 60 / 60 / 24).toFixed() === String(value / 1000 / 60 / 60 / 24)) return { value: (value / 1000 / 60 / 60 / 24), unit: 'Day' }
  if (Number(value / 1000 / 60 / 60).toFixed() === String(value / 1000 / 60 / 60)) return { value: (value / 1000 / 60 / 60), unit: 'Hr' }
  if (Number(value / 1000 / 60).toFixed() === String(value / 1000 / 60)) return { value: (value / 1000 / 60), unit: 'Min' }
  if (Number(value / 1000).toFixed() === String(value / 1000)) return { value: (value / 1000), unit: 'Sec' }

  return { value: value, unit: 'MSec' }
}

export const predictBestSizeUnitFromBytesValue = async (value) => {
  if (Number(value / 1024 / 1024).toFixed() === String(value / 1024 / 1024)) return { value: (value / 1024 / 1024), unit: 'Megabytes' }
  if (Number(value / 1024).toFixed() === String(value / 1024)) return { value: (value / 1024), unit: 'Kilobytes' }

  return { value: value, unit: 'Bytes' }
}

export const fetchUserData = async (id) => {
  const data = await getUserName({ id })

  return { id, ...data.data.data }
}

export const copyCreateResource = (item, toScreen, router, type = '', dateTime, isRestore = false, idOnly = false) => {
  const tempItem = item

  if (!idOnly) delete tempItem.id
  delete tempItem.createdOn
  delete tempItem.modifiedOn
  if (!isRestore) delete tempItem.roles

  tempItem.name = isRestore ? `${item.name} - Restore - ${dateTime}` : `${item.name} - Copy - ${dateTime}`

  router.push({ name: toScreen, params: { copy: tempItem, type: type ? type : '' } })
}

export const copyCreateTemplate = async (item) => {
  try {
    const templateRes = await getTemplate({ templateId: item.id })

    const tempItem = templateRes.data.data

    const translationRes = await getTranslations({ templateId: item.id })

    const tempTranslations = translationRes.data.data.items.map((x) => {
      x.isEdit = true

      return x
    })

    delete tempItem.id
    delete tempItem.createdOn
    delete tempItem.modifiedOn
    delete tempItem.roles

    const dateTimeFilter = Vue.options.filters['formatDateTime']

    tempItem.name = `${item.name} - Copy - ${dateTimeFilter(new Date())}`
    router.push({ name: 'templateCreate', params: { copy: JSON.stringify({ tempItem, tempTranslations }) } })

    return true
  } catch (err) {
    return err
  }
}

export const restoreResourceFromHistory = (item, resourceType, toScreen, router, dateTime) => {
  const tempItem = item

  delete tempItem.id
  delete tempItem.createdOn
  delete tempItem.modifiedOn
  delete tempItem.roles

  tempItem.name = `${item.name} - Restore - ${dateTime}`

  const params = { restore: tempItem }

  if (resourceType === 'PROCESS_CREDENTIAL') {
    params.type = item.type.toLowerCase()
  }

  if (resourceType === 'SETTING') {
    switch (item.type) {
    case 'INSTANCE_PROCESSING':
      params.type = 'INSTANCE_PROCESSING'.toLowerCase().replace('-', '_')
      break
    case 'PROCESS':
      params.type = 'PROCESS'.toLowerCase().replace('-', '_')
      break
    case 'PLUGIN':
      params.type = 'PLUGIN'.toLowerCase().replace('-', '_')
      break
    case 'INSTANCE_ADMIN':
      params.type = 'INSTANCE_ADMIN'.toLowerCase().replace('-', '_')
      break
    case 'GLOBAL_SETTING':
      params.type = 'GLOBAL_SETTING'.toLowerCase().replace('-', '_')
      break
    case 'SETTING':
      params.type = 'SETTING'.toLowerCase().replace('-', '_')
      break
    default:
      break
    }
  }

  router.push({ name: toScreen, params })
}

const msToHMS = ( ms ) => {

  if (ms < 1001) return ms + ' ms'

  if (ms < 60000) return (ms / 1000).toFixed(2) + ' sec'

  if (ms < 3600000) return (ms / 1000 / 60).toFixed(2) + ' min'

  const date = new Date(ms)

  // Extract hours and minutes
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()

  return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' HH:mm'
}

export const calculateDuration = (startTime, endTime) => {

  if (!startTime || !endTime) return msToHMS(0)

  return msToHMS(new Date(endTime).valueOf() - new Date(startTime).valueOf())
}

export const roleChecker = (userRoles, rolesArray) => {
  return !(userRoles && userRoles.some((role) => rolesArray.includes(role.name)))
}

export const recursion = (arr, localStep) => {
  let stepValue = []
  let bool = false

  arr.forEach((item) => {
    const id = typeof localStep?.localId === 'string' ? localStep?.localId.split('.')[0] : localStep?.localId
    const itemId = typeof item?.localId === 'string' ? item?.localId.split('.')[0] : item?.localId

    if (itemId > id) {
      bool = true
    } else {
      bool = false
      if (localStep?.localId + '' >= item?.localId + '' && ('' + item.localId)?.split('.')[0] === ('' + localStep?.localId)?.split('.')[0]) {
        if (item.type === 'FOREACH') {
          stepValue.push({ 'key': item?.properties?.recordName })
        }
      }
    }

    if (item.properties?.set && !bool && (localStep?.localId + '' >= item?.localId + '' || +localStep?.localId > +item?.localId)) {
      for (const key in item?.properties?.set) {
        stepValue.push({
          key,
          localId: item.localId,
          value: item.properties.set[key],
          type: item.type
        })
      }
    } else if (item.type === 'FOREACH' && !bool) {
      if (item.properties?.steps.length) {
        const stepsArray = recursion(item.properties?.steps, stepValue)

        stepsArray.forEach((step) => {
          stepValue.push(step)
        })
      }
    } else if (item.type === 'UNSET_VARIABLES' && !bool) {
      stepValue = stepValue.filter( ( el ) => {
        return !item?.properties?.variables.find((objFromB) => {
          return el.key === objFromB
        })
      })

    } else if ((item?.properties?.steps?.length > 0 || item?.steps?.length) && !bool) {
      let stepsArray = []

      if (item?.steps?.length) {
        stepsArray = recursion(item?.steps, stepValue)
      } else {
        stepsArray = recursion(item?.properties?.steps, stepValue)

      }

      stepsArray.forEach((step) => {
        stepValue.push(step)
      })
    } else if (item?.properties?.conditions?.length > 0 && !bool) {
      const stepsArray = recursion(item?.properties?.conditions, stepValue)

      stepsArray.forEach((step) => {
        stepValue.push(step)
      })
    }

    if ((item.type === 'JDBC' ||
        item.type === 'MONGODB' ||
        item.type === 'CSV' ||
        item.type === 'EXECUTE_EXTERNAL_COMMAND' ||
        item.type === 'IMAGE' ||
        item.type === 'JDBC' ||
        item.type === 'JWT' ||
        item.type === 'PDF' ||
        item.type === 'UUID') &&
      !bool)
    {
      if (item.properties?.targetObject)
        stepValue.push({ 'key': item.properties?.targetObject })
    } else if (item.type === 'USER') {
      if (item.properties?.fields?.targetObject)
        stepValue.push({ 'key': item.properties?.fields?.targetObject })
    } else if (item.type === 'REST' && !bool) {
      if (item.properties?.restResponseCode)
        stepValue.push({ 'key': item.properties?.restResponseCode })
      if (item.properties?.targetObject)
        stepValue.push({ 'key': item.properties?.targetObject })
    } else if (item.type === 'EXECUTE_PROCESS') {
      const inputKeys = item.properties?.input !== null ? Object.keys(item.properties?.input) : []
      const outputKeys = item.properties?.output !== null ? Object.keys(item.properties?.output) : []
      const inputOutputArr = [...inputKeys, ...outputKeys]

      inputOutputArr.forEach((key) => {
        stepValue.push({ 'key': key })
      })
    }
  })

  return stepValue
}

export const getAllStepsData = (processSteps, formatFunction = (step) => step) => {
  const allSteps = []

  const addStepWithInnerSteps = (stepData, isCatch = false) => {
    const step = isCatch
      ? { steps: [...stepData], name: 'catch', localId: 'catch' }
      : { ...stepData }

    allSteps.push(formatFunction(step))

    if (step.properties?.conditions?.length > 0) {
      step.properties?.conditions.forEach((condition) => {
        addStepWithInnerSteps(condition)
      })
    }

    const innerSteps = step.properties?.steps || step.steps || []

    if (innerSteps.length > 0) {
      innerSteps.forEach((subStep) => {
        addStepWithInnerSteps(subStep)
      })
    }

    if (step.type === 'TRY_CATCH') {
      step?.properties?.try && addStepWithInnerSteps(step.properties.try, false)
      step?.properties?.catch && addStepWithInnerSteps(step.properties.catch, true)
      step.properties?.finally && addStepWithInnerSteps(step.properties.finally, false)
    }
  }

  processSteps.forEach((step) => {
    addStepWithInnerSteps(step)
  })

  return allSteps
}

export const removeAdditionalPropertiesFromStepStep = (allSteps, onlyDeleteSelectionIndicatorsAndSimpleCleaning = false) => {
  const localAllSteps = JSON.parse(JSON.stringify(allSteps))

  const searchStep = (items) => {
    items.forEach((item) => {

      delete item.isSelected
      delete item.logStatus
      if (item.properties?.catch?.length) {
        searchStep(item.properties.catch)
        item.properties.catch.forEach((item2) => {
          delete item2.isSelected
          searchStep(item2.steps)
        })
      }
      if (item.properties?.conditions?.length) {
        searchStep(item.properties.conditions)
        item.properties.conditions.forEach((item2) => {
          delete item2.isSelected
          searchStep(item2.steps)
        })
      }
      if (
        !item.librariesNames 
        || (Array.isArray(item.librariesNames) 
          && !item.librariesNames?.length)
      ) item.librariesNames = null
      for (const row in item.properties) {
        // eslint-disable-next-line no-prototype-builtins
        if (item.properties.hasOwnProperty(row)) {
          if (row !== 'steps' && !item.properties[row]) item[row] = item.properties[row] = null
          if (row === 'librariesNames') {
            if (
              !item.properties?.librariesNames 
              || (Array.isArray(item.properties?.librariesNames) 
                && !item.properties?.librariesNames?.length)
            ) item.properties.librariesNames = null
          }
          if (row === 'attachments') {
            if (!item.properties?.attachments || (Array.isArray(item.properties?.attachments) && !item.properties?.attachments?.length)) item.properties.attachments = null
          }
        }
      }
      if (onlyDeleteSelectionIndicatorsAndSimpleCleaning) {
        return
      }

      item.localId = item.localId ? item.localId.toString() : null

      delete item.authType

      delete item.librariesNames

      delete item.attachments

      if (item.properties?.pluginObj) delete item.properties.pluginObj

      if (item.properties?.librariesNames && item.properties?.librariesNames.length === 0) item.properties.librariesNames = null

      if (item.properties?.attachments && item.properties?.attachments.length === 0) item.properties.attachments = null

      if (item.type === 'REST' && item.properties?.authType === 'NONE') item.properties.authType = null

      if (item.type === 'REST' && item.properties?.timeout) item.properties.timeout = item.properties.timeout.toString()

      delete item.id
      delete item.mermaidId

      if (
        item.properties &&
        item.properties.steps &&
        item.properties.steps.length > 0
      ) {
        searchStep(item.properties.steps)
      }
      if (
        item.properties?.try?.steps &&
        item.properties.try.steps.length > 0
      ) {
        searchStep(item.properties.try.steps)
      }
      if (
        item.properties?.finally?.steps &&
        item.properties.finally.steps.length > 0
      ) {
        searchStep(item.properties.finally.steps)
      }
      if (
        item.properties &&
        item.properties.catch &&
        item.properties.catch.length > 0
      ) {
        item.properties.catch.forEach((item2) => {
          delete item2.id
          delete item2.mermaidId
        })
      }
      if (
        item.properties &&
        item.properties.conditions &&
        item.properties.conditions.length > 0
      ) {
        item.properties.conditions.forEach((item2) => {
          delete item2.id
          delete item2.mermaidId
        })
      }
    })
  }

  searchStep(localAllSteps)

  return localAllSteps
}

export const doesProcessNameExist = async (processName) => (
  await getProcesses({ name: processName })
).data.data.items.find((x) => x.name === processName)

const getProcessById = async (id) => {
  return new Promise((resolve, reject) => {
    getProcess({ id })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const getProcessByName = async (name) => {
  return new Promise((resolve, reject) => {
    getProcesses({ request: { page: 1, size: 1, filter: { name } } })
      .then((res) => {
        const findCorrectProcess = res.data.data.items.find((x) => x.name === name)

        if (findCorrectProcess) {
          resolve(findCorrectProcess)
        } else {
          resolve(false)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const createProcessForTriggerData = async (triggerName, processNameExists = false, roles) => {
  const rolesWithoutAuth = await getRolesWithoutAuth()
  const rolesWithoutAuthIds = rolesWithoutAuth.map((role) => role.id)
  const rolesCleaned = roles.filter((role) =>
    rolesWithoutAuthIds.includes(role.roleId)
  )

  const res = await createProcess({ body: {
    id: 0,
    createdOn: '',
    modifiedOn: '',
    name: processNameExists
      ? `${triggerName} - ${format(new Date(), 'yyyy-MM-dd HH:mm')}`
      : triggerName,
    status: 'ACTIVE',
    logsTtlInMSec: 86400000,
    errorsTtlInMSec: 86400000,
    maxProcessingDurationInMSec: 60000,
    maxSimultaneousExecutions: 1,
    overallSimultaneousExecutions: 1,
    simultaneousExecutionsPerInstance: 1,
    steps: {
      steps: [
        {
          enabled: true,
          id: 1,
          isSelected: false,
          name: 'Placeholder',
          properties: {
            message: 'Placeholder'
          },
          type: 'LOG',
          enableStepLog: true,
          enableCacheLog: true,
          localId: 1,
          mermaidId: 1
        }
      ]
    },
    isGdprRelevant: false,
    roles: rolesCleaned,
    isCacheStepLogsEnabled: true,
    isStepLogsEnabled: true,
    comment: '',
    inputValidationRuleId: '',
    outputValidationRuleId: '',
    isSystem: false
  } })

  return res
}

export const canUserEditResource = (isSuperUser, editRole, isSystem) => {
  if (isSystem) return false

  if (isSuperUser) return true

  return editRole
}

export const filterUniqueObjectsByDataIdAndName = (value, index, self) => {
  return (
    self.findIndex(
      (obj) => obj.data.name === value.data.name && obj.data.id === value.data.id
    ) === index
  )
}

export const getRolesWithoutAuth = async () => {
  const roles = await getRoles()

  return roles.data.data.items.filter(
    (role) => role.name !== 'AUTHENTICATED' && role.name !== 'UNAUTHENTICATED'
  )
}

export const estimatedTimeOfExecution = (start, end) => {
  if (start && end) {
    const startInner = new Date(start)
    const endInner = new Date(end)
    const diff = endInner - startInner

    return diff / 1000
  }

  return 'N/A'
}
