<template>
  <v-list nav dense>
    <div v-for="(item, index) in menu" :key="index">
      <div v-if="item.text && !mini" class="pa-1 mt-2 overline">
        <p style="margin-top: -1.45rem;">{{ item.text }}</p>
      </div>
      <div v-if="mini" class="pa-1 mt-2 overline"></div>
      <flowy-nav-menu :menu="item.items" :mini="mini" />
    </div>
    <v-list-item active-class="primary--text" link style="margin-top: -2.5rem;" @click="openDocs()">
      <v-list-item-icon>
        <v-icon :class="{ 'white--text': $vuetify.theme.dark }">
          mdi-clipboard-outline
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title :class="{ 'white--text': $vuetify.theme.dark }">
          Docs
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import FlowyNavMenu from './FlowyNavMenu'

export default {
  components: {
    FlowyNavMenu
  },
  props: {
    mini: {
      type: Boolean
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      docs: 'https://getflowy.io/docs'
    }
  },
  methods: {
    openDocs() {
      window.open(this.docs, '_blank')
    }
  }
}
</script>
