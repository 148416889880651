module.exports = {
  username: 'Username',
  password: 'Password',
  name: 'Name',
  status: 'Status',
  actions: 'Actions',
  active: 'Active',
  inactive: 'Inactive',
  phone: 'Phone',
  email: 'Email',
  search: 'Search',
  export: 'Export',
  from: 'From',
  to: 'To',
  action: 'Action',
  allNone: 'All/None',
  first: 'First',
  last: 'Last',
  model: 'Model',
  default: 'Default',
  registeredOn: 'Registered on',
  lastModifiedOn: 'Last modified on',
  createdOn: 'Created on',
  modifiedOn: 'Modified on',
  hour: 'Hour',
  minute: 'Minute',
  mandatory: 'Mandatory',
  response: 'Response',
  notifications: 'Notifications',
  text: 'Text',
  themes: 'Themes',
  details: 'Details',
  download: 'Download',
  instance: 'Instance',
  backendversion: 'Backend',
  ipAddress: 'IP Address',
  portNumber: 'Port Number',
  changePassword: 'Change password',
  system: 'System',
  created: 'Created',
  updated: 'Updated',
  cancellation: 'Cancel',
  print: 'Print',
  register: 'Register',
  number: 'No',
  mode: 'Mode',
  dark: 'Dark',
  fontSizes: 'Font Sizes',
  theme: 'Themes',
  none: 'None',
  successful: 'Successful',
  open: 'Open',
  failed: 'Failed',
  activeOnly: 'Active Only',
  keyType: 'Key Type',
  payload: 'Payload',
  privateKey: 'Private Key',
  signatureAlgorithm: 'Signature Algorithm',
  url: 'Url',
  maxPoolSize: 'Max Pool Size',
  host: 'Host',
  port: 'Port',
  sid: 'SID',
  token: 'Token',
  apiVersion: 'API Version',
  appId: 'APP ID',
  aud: 'AUD',
  id: 'ID',
  maxProcessingDurationInMSec: 'Max Processing Duration in MSec',
  maxProcessingDuration: 'Max Processing Duration',
  process: 'Process',
  triggerCondition: 'Trigger Condition',
  seconds: 'Seconds',
  minutes: 'Minutes',
  minutesInterval: 'Minutes Interval',
  hours: 'Hours',
  hoursInterval: 'Hours Interval',
  dayOfMonth: 'Day Of Month',
  month: 'Month',
  dayOfWeek: 'Day Of Week',
  method: 'Method',
  maxRequestSize: 'Max Request Size',
  frontEnd: 'Front end',
  searchByName: 'Search By Name',
  finishedOn: 'Finished On',
  startedOn: 'Started On',
  instanceId: 'Instance ID',
  eventId: 'Event ID',
  threadId: 'Thread ID',
  date: 'Date',
  clientId: 'Client ID',
  clientSecret: 'Client Secret',
  selectProcessFirst: 'Select process first',
  event: 'Event',
  exceptionMessage: 'Exception Message',
  exceptionStackTrace: 'Exception Stack Trace',
  stepId: 'Step ID',
  maxSimultaneousExecutions: 'Max Simultaneous Executions',
  saveProcessToEditSteps: 'Save Process To Edit Steps',
  defaultStepsName: 'Steps Name',
  properties: 'Properties',
  stepProperties: 'Step Properties',
  enabled: 'Step enabled',
  query: 'Query',
  targetObject: 'Target Object',
  credential: 'Credential',
  recordName: 'Record Name',
  triggerId: 'Trigger ID',
  type: 'Type',
  processingStartOn: 'Processing started on',
  showCache: 'Cache',
  list: 'List',
  json: 'JSON',
  async: 'Asynchronous execution',
  input: 'Input',
  output: 'Output',
  js: 'JavaScript',
  switch: 'Switch',
  collectionName: 'Collection Name',
  queryUpdate: 'Query Update',
  mailTemplate: 'Mail Template',
  variables: 'Variables',
  subject: 'Subject',
  restResponseCode: 'REST response code target object',
  targetUrl: 'Target Url',
  timeout: 'Timeout in MSec',
  body: 'Body',
  headers: 'Headers',
  queryParams: 'Query Params',
  channelId: 'Channel ID',
  message: 'Message',
  logsTtlInMSec: 'Time-to-live for other logs',
  stepName: 'Step Name',
  copy: 'Copy',
  copyIdToClipboard: 'Copy ID to Clipboard',
  steps: 'Steps',
  filter: 'Filter',
  roles: 'Roles',
  step: 'Step',
  childStep: 'Child Step',
  permissions: 'Permissions',
  code: 'Code',
  assignable: 'Assignable',
  yes: 'Yes',
  no: 'No',
  textTemplate: 'Text Template',
  en: 'English',
  de: 'Deutsch',
  language: 'Language',
  connectionTimeout: 'Connection Timeout',
  writeTimeout: 'Write Timeout',
  template: 'Template',
  grant_type: 'Grant type',
  refresh_token: 'Refresh token',
  setPasswordUrl: 'Set Password Url',
  maxTriggerHttpThreads: 'Max Trigger Http Threads',
  maxTriggerHttpProcessingInMSec: 'Max Trigger Http Processing Duration',
  maxProcessingThreads: 'Max Processing Threads',
  permissionType: 'Permission Type',
  unit: 'Unit',
  changeableTimeout: 'Timeout',
  secondsInterval: 'Seconds Interval',
  account: 'Account',
  yourAccount: 'Your Account',
  setPassword: 'Set Password',
  confirmPassword: 'Confirm Password',
  baseUrlRest: 'Base Url Rest',
  stepType: 'Step Type',
  gdpr: 'GDPR Relevant',
  history: 'History',
  notSet: 'Not set',
  defaultLocale: 'Default Locale',
  global: 'Global',
  local: 'Local',
  est: 'Trace',
  addProcessName: 'Add Process Name',
  visualId: 'Visual ID',
  bg: 'Bulgarian',
  zh: 'Chinese (Simplified)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  el: 'Greek',
  hu: 'Hungarian',
  ga: 'Irish',
  it: 'Italian',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mt: 'Maltese',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  sk: 'Slovak',
  sl: 'Slovene',
  es: 'Spanish',
  sv: 'Swedish',
  tr: 'Turkish',
  uk: 'Ukrainian',
  validation: 'Validation',
  permission: 'Permission',
  exist: 'Exist',
  missingParams: 'Missed Required Resources',
  fileLoaded: 'File Loaded',
  scriptEngine: 'Script Engine',
  dataType: 'Data Type',
  ruleType: 'Rule Type',
  fields: 'Fields',
  fieldName: 'Field Name',
  required: 'Required',
  rules: 'Rules',
  field: 'Field',
  rule: 'Rule',
  validationRules: 'Validation Rules',
  logServerUrl: 'Log Server Url',
  logs: 'Logs',
  apiKey: 'Api Key',
  listOfCodes: 'List of HTTP codes',
  logsServer: 'Log Server',
  nextLogStep: 'Next Log Step',
  previousLogStep: 'Previous Log Step',
  fetchLastEventLogs: 'Fetch Last Events Logs',
  debug: 'Debug',
  processSettingName: 'Setting',
  UPLOAD: 'Upload',
  DOWNLOAD: 'Download',
  descriptions: 'Descriptions',
  loadDirection: 'Load Direction',
  encrypt: 'Encrypt',
  restResponseContentTargetObject: 'REST response content target object',
  enableStepLog: 'Logging',
  urlForAdmin: 'Healthcheck URL for admin',
  urlForProcessingService: 'Healthcheck URL for the processing service',
  searchByModuleName: 'Search by Module Name',
  description: 'Description',
  basicAccessAuthentication: 'Allow Basic Authentication',
  upDownload: 'Up/Download',
  remoteId: 'Remote Id',
  exception: 'Exception',
  className: 'Class Name',
  checksum: 'Checksum',
  stepTypes: 'Step Types',
  plugin: 'Plugin',
  selectPlugin: 'Select Plugin First',
  processLogs: 'Process logs',
  openProcessLogs: 'Logs',
  accessKeyId: 'Access Key Id',
  region: 'Region',
  s3Bucket: 'S3 Bucket',
  secretKey: 'Secret Key',
  content: 'Content',
  key: 'Key',
  path: 'Path',
  events: 'Events',
  backEnd: 'Back end',
  isCacheStepLogsEnabled: 'Cache storage',
  isStepLogsEnabled: 'Logging',
  enableCacheLog: 'Cache storage',
  tos: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  passphrase: 'Passphrase',
  queryConsole: 'Query Console',
  console: 'Console',
  affectedRows: 'Affected Rows',
  success: 'Success',
  respondSent: 'Respond sent',
  resultDetails: 'Result Details',
  engine: 'Engine',
  isBodyScript: 'Body Script',
  groovy: 'Groovy',
  last15MinutesCount: 'Last 15 minutes',
  last24HoursCount: 'Last 24 hours',
  last60MinutesCount: 'Last 60 minutes',
  todayCount: 'Today',
  executions: 'Executions',
  eventStates: 'Event states',
  childEventIdPath: 'Child event ID target object',
  folder: 'Folder',
  sizeInBytes: 'Size In Bytes',
  readType: 'Read Type',
  maxResults: 'Max Results',
  recipient: 'Recipient',
  sender: 'Sender',
  emailId: 'Email Id',
  locale: 'Locale',
  heartbeat: 'Heartbeat',
  user: 'User',
  overview: 'Overview',
  review: 'Review',
  view: 'View',
  title: 'Title',
  rating: 'Rating',
  reviews: 'Reviews',
  submitReview: 'Submit Review',
  metricServerUrl: 'Metric Server Url',
  visualisationServerUrl: 'Visualisation Server Url',
  metricServer: 'Metric Server',
  visualisationServer: 'Visualisation Server',
  internalJobs: 'Internal Jobs',
  started: 'Started',
  hideChildren: 'Hide children',
  showChildren: 'Show children',
  diagrams: 'Diagrams',
  genericSubType: 'Member type',
  userId: 'User ID',
  filters: 'Filters',
  source: 'Source Object',
  attachments: 'Attachments',
  showAttachments: 'Show attachments',
  contentType: 'Content type',
  includeHeaders: 'Include headers in output',
  openProcessEvents: 'Events',
  validations: 'Validations',
  allowQueryConsole: 'Allow usage of Query Console',
  stats: 'Statistics',
  available: 'Available',
  used: 'Used',
  processing: 'Processing',
  http: 'Http',
  threadStatistics: 'Thread statistics',
  lastUpdateHeartbeat: 'Last update / Heartbeat',
  delimiter: 'Delimiter',
  qualifier: 'Text Qualifier',
  customDelimiter: 'Custom delimiter',
  cc: 'CC',
  bcc: 'BCC',
  singleResult: 'Single result',
  comment: 'Comment',
  debugLogData: 'Debug log data',
  debugLog: 'Debug',
  width: 'Width',
  height: 'Height',
  format: 'Format',
  exampleVariables: 'Example variables',
  all: 'All',
  profile: 'Profile',
  visuals: 'Visuals',
  general: 'General',
  light: 'Light',
  colors: 'Colors',
  overallSimultaneousExecutions: 'Overall max simultaneous exec.',
  simultaneousExecutionsPerInstance: 'Max. simultaneous exec. per instance',
  processId: 'Process ID',
  showGraph: 'Show graph',
  processMermaidType: 'Show horizontal graph',
  command: 'Command',
  resultCode: 'Result code',
  resultErrors: 'Result errors',
  forgotYourPassword: 'Forgot your password?',
  try: 'Try',
  catch: 'Catch',
  exceptions: 'Exceptions',
  optionalQueryName: 'Query name',
  topic: 'Topic',
  groupId: 'Group ID',
  bootstrapServers: 'Bootstrap servers',
  groupNodes: 'Group nodes',
  messageKey: 'Message key',
  service: 'Service',
  count: 'Count',
  daily: 'daily',
  monthly: 'monthly',
  yearly: 'yearly',
  healthcheckURL: 'Healthcheck URL',
  failures: 'Failures',
  firstOccurrence: 'First occurrence',
  lastOccurrence: 'Last occurrence',
  counter: 'Counter',
  processName: 'Process name',
  display: 'Display',
  showCreatedOn: 'Show Created On',
  hostUrl: 'Host URL',
  installerId: 'Installer ID',
  softwareHouseId: 'Software House ID',
  reportType: 'Report type',
  accepted: 'Accepted',
  requestId: 'Request ID',
  terminalId: 'Terminal ID',
  transaction: 'Transaction',
  amount: 'Amount',
  amountCashback: 'Amount cashback',
  amountPreAuthorisedTotal: 'Amount pre-authorised total',
  authCode: 'Auth code',
  cardholderNotPresent: 'Cardholder not present',
  currency: 'Currency',
  transactionType: 'Transaction type',
  uri: 'URI',
  queue: 'Queue',
  deliveryMode: 'Delivery mode',
  priority: 'Priority',
  timeToLive: 'Time to live',
  destinationType: 'Destination type',
  destinationName: 'Destination name',
  copyPaste: 'Copy & paste',
  finally: 'Finally',
  receivingInstanceId: 'Receiving instance ID',
  blue: 'Blue',
  instanceIdlong: 'Executing instance ID',
  jwt: 'JWT',
  merchantUrl: 'Merchant URL',
  accessToken: 'Access token',
  crossReference: 'Cross reference',
  cv2: 'CV2',
  merchantTransactionId: 'Merchant transaction ID',
  customerId: 'Customer ID',
  currencyCode: 'Currency code',
  newTransaction: 'New transaction',
  orderDescription: 'Order description',
  orderId: 'Order ID',
  shippingDetails: 'Shipping details',
  userAddress1: 'Address 1',
  userAddress2: 'Address 2',
  userAddress3: 'Address 3',
  userAddress4: 'Address 4',
  userAddress: 'Address',
  userCity: 'City',
  userCountry: 'Country',
  userEmail: 'Email',
  userCountryCode: 'Country code',
  userPostcode: 'Postcode',
  userState: 'State',
  transactionSource: 'Transaction source',
  userAgent: 'User agent',
  userEmailAddress: 'Email address',
  userIpAddress: 'IP address',
  userPhoneNumber: 'Phone number',
  waitPreExecute: 'Wait for pre-execute',
  webHookUrl: 'Webhook URL',
  metaData: 'Meta data',
  showModifiedOn: 'Show Modified On',
  showId: 'Show Id',
  cantDelete: 'Can\'t delete resource. Select Force flag and try again to force delete this resource!',
  connectedResources: 'Resources connected to this resource',
  roleId: 'Role ID',
  swagger: 'Swagger',
  showComments: 'Comments',
  show: 'Show',
  connectedUsers: 'Users connected to this resource',
  noConnectedResources: 'No resources connected to this resource',
  lastLogin: 'Last login',
  logsErrTtlInMSec: 'Time-to-live for error logs',
  includesAnotherValidation: 'Includes validations',
  advancedOptions: 'Advanced options',
  customFunctions: 'Flowy functions',
  values: 'Values',
  value: 'Value',
  condition: 'Condition',
  regex: 'Regex',
  queryName: 'Query name',
  result: 'Result',
  inputMatchesRegex: 'Input matches regex',
  queryConditions: 'Query conditions',
  exchange: 'Exchange',
  virtualHost: 'Virtual host',
  routingKey: 'Routing key',
  resourceType: 'Resource type',
  entries: 'Entries',
  sourceObject: 'Source object',
  role: 'Role',
  toChange: 'To change',
  thisWillOverwrite: 'This will overwrite selected resources',
  toggle: 'Toggle',
  processValidation: 'Process validation',
  propertyAction: 'Property / Action',
  projection: 'Projection',
  useSslProtocol: 'Use SSL protocol',
  converts: 'Converts',
  library: 'Library',
  libraries: 'Libraries',
  import: 'Import',
  parentProcess: 'Parent process',
  parentEvent: 'Parent event',
  expiresIn: 'Expires in (sec)',
  showFinishedOn: 'Show Finished On',
  processDebugger: 'Process debugger',
  showStepType: 'Show Step Type',
  senderAddress: 'Sender email address',
  admin: 'Admin',
  passwordChange: 'Password change',
  oldPassword: 'Old password',
  newPassword: 'New password',
  serviceDesk: 'Service Desk',
  pattern: 'Pattern',
  clientKey: 'Client Key (PEM)',
  xml: 'XML',
  xslt: 'XSLT',
  python: 'Python',
  maxEmailSendingThreads: 'Max. email sending threads',
  searchResources: 'Search resources',
  noResultsFound: 'No results found',
  addedResources: 'Added resources',
  noResourcesAdded: 'No resources added',
  thisWillAdd: 'This will add additional values to the existing ones',
  thisWillRemove: 'This will remove values from the existing ones if they exist',
  emails: 'Emails',
  statistics: 'Statistics',
  filterByProcess: 'Filter by process',
  eventCreatedOn: 'Event created on',
  eventModifiedOn: 'Event modified on',
  finished: 'Finished',
  scheme: 'Scheme',
  retained: 'Retained',
  qos: 'QoS',
  duration: 'Duration',
  allSessionsDeleted: 'All sessions deleted',
  expireOn: 'Expire on',
  responseCode: 'Response code',
  qraphQlResponseContentTargetObject: 'GraphQL response content target object',
  diagram: 'Diagram',
  debugger: 'Debugger',
  listenAllProcesses: 'Listen all processes',
  listenProcess: 'Listen process',
  listenStatus: 'Listen status',
  listenType: 'Listen type',
  listenProcessId: 'Listen process ID',
  showListenAllProcesses: 'Only listening to all processes',
  searchType: 'Search type',
  unique: 'Unique',
  relation: 'Relation',
  entityRequest: 'Entity request',
  entity: 'Entity',
  customEntity: 'Custom entity',
  generateEntity: 'Generate entity',
  existingPersistenceName: 'Existing persistence name',
  deletePersistence: 'Delete persistence',
  deleteResources: 'Delete resources',
  primaryKey: 'Primary key',
  relations: 'Relations',
  persistence: 'Persistence',
  errorHandler: 'Error handler',
  translations: 'Translations',
  resourceAction: 'Resource action',
  resources: 'Resources',
  resourceId: 'Resource ID',
  conflict: 'Conflict',
  foreignKey: 'Foreign key',
  generation: 'Generation',
  rootCertificate: 'Root certificate',
  rootCertificatePassword: 'Root certificate password',
  clientCertificate: 'Client certificate',
  clientPrivateKey: 'Client private key',
  instances: 'Instances',
  versionEntity: 'Version Entity',
  versionPersistence: 'Version Persistence',
  generated: 'Generated',
  existing: 'Existing',
  deletedResources: 'Deleted resources',
  nonExistent: 'Non-existent',
  parentRelations: 'Parent relations',
  childRelations: 'Child relations',
  activeIn: 'Active in',
  allSteps: 'All steps',
  changeInAllSteps: 'Change in all steps',
  massModifyAllSteps: 'Mass modify all steps',
  twoFaAuth: 'Two-factor auth',
  enabledTwoFaAuth: 'Two factor authentication enabled',
  disabledTwoFaAuth: 'Two factor authentication disabled',
  scanQrCode: 'Scan QR code with your authenticator app',
  otp: 'One-time password',
  submitOtp: 'Submit OTP to activate',
  disable: 'Disable',
  twoFaAuthentication: 'Two-factor authentication',
  activeTriggers: 'There are still triggers calling this process. Would you like to deactivate these too?',
  reloadData: 'Reload data',
  memory: 'Memory',
  cpu: 'CPU',
  executionsSumUp: 'Executions sum up',
  instanceHeartbeatInMSec: 'Instance heartbeat',
  syncSettingsDelayInMSec: 'Sync settings delay',
  systemSchedulerPoolSize: 'System scheduler pool size',
  userRegisterTokenTtlInMSec: 'User register token TTL',
  inactiveTtlInMSec: 'Inactive TTL',
  syncDelayInMSec: 'Sync delay',
  cleanUpDelayInMSec: 'Clean up delay',
  heartbeatInMSec: 'Heartbeat',
  timeoutInMSec: 'Timeout',
  cleanUpErrorsDelayInMSec: 'Clean up errors delay',
  cleanUpLogsDelayInMSec: 'Clean up logs delay',
  executeProcessStepChildEventDelayInMSec: 'Execute process step child event delay',
  fetchEventsDelayInMSec: 'Fetch events delay',
  fetchedTtlInMSec: 'Fetched TTL',
  fixExecutionStateDelayInMSec: 'Fix execution state delay',
  eventsCount: 'Events count',
  handleDelayInMSec: 'Handle delay',
  prometheusMetricsWhitelist: 'Prometheus metrics whitelist',
  waitEventTimeInMSec: 'Wait event time',
  ttlInMSec: 'TTL',
  cleanDelayInMSec: 'Clean delay',
  cleanEnabled: 'Clean enabled',
  internalJobsDelayInMSec: 'Internal jobs delay',
  moduleVersion: 'Module version',
  filterRoles: 'Filter roles',
  encoding: 'Encoding',
  sourceEncoding: 'Source encoding',
  targetEncoding: 'Target encoding',
  selectRelationsLevel: 'Relationsstufe',
  defaultValue: 'Default value',
  cantSetDefaultValueIfRequired: 'Can\'t set if field is required',
  ipAddresses: 'IP address(es)',
  searchMatchingPattern: 'Search matching pattern',
  inputValidation: 'Input validation',
  outputValidation: 'Output validation',
  cantSetIfFieldNoVarcharAndSearchable: 'Can\'t set if field is not varchar and searchable',
  authType: 'Auth type',
  cantSetIfFieldIsNoSearchable: 'Can\'t set if field is not searchable',
  isSftp: 'Secure FTP',
  triggerName: 'Trigger name',
  isSystem: 'System',
  report: 'Report',
  existingObjects: 'Existing objects',
  newObjects: 'New objects',
  missingResources: 'Missing resources',
  conflicts: 'Conflicts',
  exists: 'Exists',
  missing: 'Missing',
  affectedTriggers: 'Affected triggers by name change',
  includeSystem: 'Include System',
  XML: 'XML',
  enableDebugLog: 'Debug',
  isDebugLogEnabled: 'Debug',
  uploadCertificate: 'Upload certificate',
  persistenceUpdate: 'Persistence update',
  persistenceNew: 'Persistence new',
  skip: 'Skip',
  limit: 'Limit',
  environmentName: 'Environment name',
  sortable: 'Sortable',
  sortField: 'Sort field',
  sortDirection: 'Sort direction',
  stopExecution: 'Stop execution',
  areYouSureExecutionStop: 'Are you sure you want to stop the execution?',
  refreshStatistics: 'Refresh statistics',
  shouldFinishBy: 'Should finish by',
  estimatedTimeOfExecution: 'Max Execution Time',
  actualTimeOfExecution: 'Actual Execution Time',
  OCR: 'OCR',
  QR: 'QR',
  image: 'Image',
  size: 'Size',
  messages: 'Messages',
  cleanUpBatchSize: 'Clean up batch size'
}
